angular.module("app")
    .controller("cardAddController", ["$q", "$scope", "$rootScope", "$log", "$state", "$stateParams", "Notification", "paymentMethodService", "travelPolicyService", "selectedDepartment", function ($q, $scope, $rootScope, $log, $state, $stateParams, Notification, paymentMethodService, travelPolicyService, selectedDepartment) {
        var vm = this;
        vm.loading = false;
        vm.loadingForSave = false;
        vm.notifyOrganisation = false;
        vm.paymentMethod = {
            travelPolicy: undefined
        };
        vm.effectivePolicy = [];
        vm.availablePolicies = [];

        (function init() {

            if (_.isEmpty($stateParams.organisationReference)) {
                selectedDepartment.clearDepartment();
            } else {
                selectedDepartment.setDepartment($stateParams.organisationReference);
            }

            bindLoaderStateEvents();
            initNotificationTypeOptions();
            loadTravelPolicies();
        })();

        vm.addPaymentMethod = function () {
            vm.loadingForSave = true;
            applyTravelPolicy();
            var paymentMethod = angular.copy(vm.paymentMethod);
            paymentMethod.card.expiryYear = vm.paymentMethod.card.expiryYear % 100;

            paymentMethod.card.notificationType = buildNotificationType();

            return paymentMethodService.create(paymentMethod)
                .then(function (results) {
                    $state.go("payment-methods");
                })
                .catch(function (resp) {
                    if (resp.data.messages) {
                        _.each(resp.data.messages, function (message) {
                            Notification.error({message: message, delay: 5000});
                        });
                    } else {
                        Notification.error({
                            message: 'There was a problem adding this card, please refresh and try again',
                            delay: 5000
                        });
                    }
                }).finally(function () {
                    vm.loadingForSave = false;
                });
        };

        vm.dayInOrder = function (day) {
            return travelPolicyService.dayOfWeek(day.dayOfWeek);
        };

        vm.collapseDetail = function () {
            //vm.hideDetail = true;
            $log.debug('Hide detail ', vm.hideDetail);
        };

        vm.effectivePolicyList = {
            accept: function () {
                if (vm.effectivePolicy.length >= 1) {
                    return false;
                } else {
                    return true;
                }
            }
        };

        function applyTravelPolicy() {
            if (vm.effectivePolicy.length > 0) {
                var policy = vm.effectivePolicy[0];
                if (!policy.isDefault) {
                    vm.paymentMethod.travelPolicy = policy;
                }
            }
        }

        function loadTravelPolicies() {
            return travelPolicyService.list(false)
                .then(function (results) {
                    $log.debug('policies %o', results);
                    if (results) {
                        results.forEach(function (policy) {
                            if (policy.isDefault) {
                                vm.effectivePolicy.push(policy);
                            } else {
                                vm.availablePolicies.push(policy);
                            }
                        });

                    }
                    if (vm.effectivePolicy.length === 0) {
                        return travelPolicyService.getDefaultPolicy()
                            .then(function (result) {
                                if (!_.isUndefined(result)) {
                                    vm.effectivePolicy.push(result);
                                    vm.availablePolicies.push({});
                                }
                            });
                    }
                    if (vm.effectivePolicy.length > 0) {
                        vm.availablePolicies.push({});
                    }
                });
        }

        vm.effectivePolicyList = {
            accept: function () {
                if (vm.effectivePolicy.length >= 1) {
                    return false;
                } else {
                    return true;
                }
            }
        };

        function initNotificationTypeOptions(notificationType) {
            var notificationTypeOptions = paymentMethodService.initPaymentMethodNotificationTypeOptions(notificationType);
            vm.notifyOrganisation = notificationTypeOptions.notifyOrganisation;
            vm.ccPassenger = notificationTypeOptions.ccPassenger;
        }

        function buildNotificationType() {
            return paymentMethodService.buildPaymentMethodNotificationType(vm.notifyOrganisation, vm.ccPassenger);
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });
        }

    }]);
